import React from 'react';
import './App.css';

import CandleEle from './components/Candle';


function App() {


  return (
    <div className="App">
      <CandleEle />
    </div>
  );
}

export default App;
